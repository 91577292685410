export const SVG_ID = 'volodya_kubitskyi';

export function animate() {
  /* eslint-disable no-unused-expressions, no-sequences */
  // DISCLAIMER
  // this function is automaticaly generated
  // do not try to understand
  // it only animates svg elements
  const actors = {};
  actors.actor_2 = {
    node: document.getElementById(`${SVG_ID}_actor_2`),
    type: 'polygon',
    cx: 50,
    cy: 50,
    dx: 50,
    dy: 22,
    opacity: 1,
  };
  actors.actor_1 = {
    node: document.getElementById(`${SVG_ID}_actor_1`),
    type: 'polygon',
    cx: 50,
    cy: 50,
    dx: 80,
    dy: 14,
    opacity: 1,
  };

  const tricks = {};
  tricks.trick_1 = (t, a) => {
    (a =
      ((n) => {
        return 0.5 > n ? 2 * n * n : -1 + (4 - 2 * n) * n;
      })(a) % 1),
      (a = (a * 1) % 1),
      (a = 0 > a ? 1 + a : a);

    const M = (a * 1 * 360 * Math.PI) / 180,
      i = t._tMatrix,
      _ = Math.cos(M),
      c = Math.sin(M),
      x = -Math.sin(M),
      s = Math.cos(M),
      h = -t.cx * Math.cos(M) + t.cy * Math.sin(M) + t.cx,
      n = -t.cx * Math.sin(M) - t.cy * Math.cos(M) + t.cy,
      r = i[0] * _ + i[2] * c,
      o = i[1] * _ + i[3] * c,
      y = i[0] * x + i[2] * s,
      f = i[1] * x + i[3] * s,
      d = i[0] * h + i[2] * n + i[4],
      e = i[1] * h + i[3] * n + i[5];

    (t._tMatrix[0] = r),
      (t._tMatrix[1] = o),
      (t._tMatrix[2] = y),
      (t._tMatrix[3] = f),
      (t._tMatrix[4] = d),
      (t._tMatrix[5] = e);
  };
  tricks.trick_2 = (_, t) => {
    (t = ((_n) => _n)(t) % 1), (t = 0 > t ? 1 + t : t);
    let i;
    i =
      0.1 >= t
        ? 1 + ((1.2 - 1) / 0.1) * t
        : t >= 0.4
        ? 1.2 - (t - 0.4) * ((1.2 - 1) / (1 - 0.4))
        : 1.2;

    const a = _._tMatrix,
      r = -_.cx * i + _.cx,
      x = -_.cy * i + _.cy,
      c = a[0] * i,
      n = a[1] * i,
      M = a[2] * i,
      f = a[3] * i,
      g = a[0] * r + a[2] * x + a[4],
      m = a[1] * r + a[3] * x + a[5];

    (_._tMatrix[0] = c),
      (_._tMatrix[1] = n),
      (_._tMatrix[2] = M),
      (_._tMatrix[3] = f),
      (_._tMatrix[4] = g),
      (_._tMatrix[5] = m);
  };

  const scenarios = {};
  scenarios.scenario_2 = {
    actors: ['actor_1'],
    tricks: [{trick: 'trick_2', start: 0, end: 1}],
    startAfter: 0,
    duration: 3000,
    actorDelay: 0,
    repeat: 0,
    repeatDelay: 3000,
  };
  scenarios.scenario_1 = {
    actors: ['actor_1'],
    tricks: [{trick: 'trick_1', start: 0, end: 1}],
    startAfter: 0,
    duration: 6000,
    actorDelay: 0,
    repeat: 0,
    repeatDelay: 0,
  };

  const tick = (t) => {
    let r, a, i, e, n, o, s, c, m, f, d, k, w;
    for (c in actors) actors[c]._tMatrix = [1, 0, 0, 1, 0, 0];
    for (s in scenarios)
      for (
        o = scenarios[s], m = t - o.startAfter, r = 0, a = o.actors.length;
        a > r;
        r++
      ) {
        if (((i = actors[o.actors[r]]), i && i.node && i._tMatrix))
          for (
            f = 0,
              m >= 0 &&
                ((d = o.duration + o.repeatDelay),
                o.repeat > 0 && m > d * o.repeat && (f = 1),
                (f += (m % d) / o.duration)),
              e = 0,
              n = o.tricks.length;
            n > e;
            e++
          )
            (k = o.tricks[e]),
              (w = (f - k.start) * (1 / (k.end - k.start))),
              tricks[k.trick] &&
                tricks[k.trick](i, Math.max(0, Math.min(1, w)));
        m -= o.actorDelay;
      }

    for (c in actors)
      (i = actors[c]),
        i &&
          i.node &&
          i._tMatrix &&
          i.node.setAttribute('transform', `matrix(${i._tMatrix.join()})`);
    requestAnimationFrame(tick);
  };
  requestAnimationFrame(tick);
}
