import {useState, useCallback} from 'react';

export const useVisible = (initialValue: boolean = false) => {
  const [isVisible, setIsVisible] = useState(initialValue);

  return {
    isVisible,
    show: useCallback(() => setIsVisible(true), []),
    hide: useCallback(() => setIsVisible(false), []),
  };
};
