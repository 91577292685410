import * as React from 'react';

const LazyAlgorithmCreatePage = React.lazy(() =>
  import(
    /* webpackChunkName: "AlgorithmCreatePage" */
    './index'
  )
);

export default LazyAlgorithmCreatePage;
