import * as React from 'react';

const LazySignInPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignInPage" */
      './index'
    )
);

export default LazySignInPage;
