import * as React from 'react';
import ReactDOM from 'react-dom';

import {App} from './App';

import './setupPolyfills';
import './setupSentry';
import './setupAnalytics';
import './setupFirebase';

ReactDOM.render(<App />, document.getElementById('root'));
