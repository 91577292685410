import 'react-app-polyfill/stable';

import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/promise';
import 'core-js/es/object';

import {Locale} from 'common/types';

// @ts-ignore
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
}
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');

  Object.values(Locale).forEach((locale) => {
    require(`@formatjs/intl-pluralrules/dist/locale-data/${locale}`);
  });
}
