import * as React from 'react';

const LazyUserPage = React.lazy(() =>
  import(
    /* webpackChunkName: "UserPage" */
    './index'
  )
);

export default LazyUserPage;
