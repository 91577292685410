import makeStyles from '@material-ui/core/styles/makeStyles';
import {Theme} from '@material-ui/core';

export default makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '@global': {
      '::selection': {
        backgroundColor: theme.palette.primary.main,
      },
      body: {
        '-webkit-tap-highlight-color': 'transparent',
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      html: {
        backgroundColor: '#7154e5',
      },
      'body, html, #root': {
        minHeight: '100vh',
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      a: {
        color: 'inherit',
      },
      '.highlight': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    loaderContainer: {
      width: '100%',
      height: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 0,
    },
  }),
  {
    name: 'Root',
  }
);
