import makeStyles from '@material-ui/core/styles/makeStyles';
import {Theme} from '@material-ui/core';

export default makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flex: '1 1',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    progress: {
      position: 'fixed',
      top: 'calc(50% - 100px)',
      left: 'calc(50% - 100px)',
      width: 200,
      height: 200,
      opacity: 0,
      transition: theme.transitions.create('opacity'),
    },
    progressShow: {
      opacity: 1,
    },
  }),
  {
    name: 'LoadingPage',
  }
);
