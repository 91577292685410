import * as React from 'react';
import Avatar, {AvatarProps} from '@material-ui/core/Avatar';

interface UserAvatarProps extends AvatarProps {
  name: string;
  photoURL?: string | null | undefined;
}

const UserAvatar: React.FunctionComponent<UserAvatarProps> = ({
  name,
  photoURL,
  ...rest
}) => {
  if (photoURL) {
    return <Avatar src={photoURL} alt={`${name} avatar`} {...rest} />;
  }
  return <Avatar {...rest}>{name ? name[0].toUpperCase() : null}</Avatar>;
};

export default UserAvatar;
