import * as React from 'react';
import ReactGA from 'react-ga';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import UserAvatar from 'components/UserAvatar';
import UserMenu from './UserMenu';
import PrivateContainer from 'components/PrivateContainer';
import NewLogo from 'components/NewLogo';
import {UserRole} from 'common/types';
import {PageRoute} from 'common/types';
import useStyles from './styles';
import {Hidden} from '@material-ui/core';
import {useCurrentUser} from 'hooks';

export const SEARCH_BAR_ID = 'search-bar';

const Header: React.FunctionComponent = () => {
  const {user} = useCurrentUser();

  const [anchorEl, setAnchorEl] = React.useState<
    React.MouseEvent['currentTarget'] | null
  >(null);

  const handleMenuOpen = (event: React.MouseEvent): void =>
    setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftSide}>
        <Link
          to={PageRoute.Index}
          onClick={() =>
            ReactGA.event({
              category: 'Header',
              action: 'logo',
            })
          }
        >
          <NewLogo className={classes.logo} />
        </Link>
      </div>
      <div className={classes.central} id={SEARCH_BAR_ID} />
      <div className={classes.rightSide}>
        <IconButton className={classes.avatarButton} onClick={handleMenuOpen}>
          <UserAvatar name={user.identifier} photoURL={user.photoURL} />
        </IconButton>
        <UserMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          getContentAnchorEl={null}
          disableAutoFocusItem
          onClose={handleMenuClose}
          onClick={() =>
            ReactGA.event({
              category: 'Header',
              action: 'userMenu',
            })
          }
        />
        <Hidden smDown>
          <PrivateContainer roles={[UserRole.Admin, UserRole.Root]}>
            <Button
              component={Link}
              to={PageRoute.Admin}
              className={classes.adminButton}
              onClick={() =>
                ReactGA.event({
                  category: 'Header',
                  action: 'admin',
                })
              }
            >
              <FormattedMessage id="Header.admin" />
            </Button>
          </PrivateContainer>
        </Hidden>
      </div>
    </div>
  );
};

export default Header;
