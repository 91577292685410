import * as React from 'react';

const LazyMapPage = React.lazy(() =>
  import(
    /* webpackChunkName: "MapPage" */
    './index'
  )
);

export default LazyMapPage;
