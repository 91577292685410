import * as React from 'react';

const LazyIndexPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "IndexPage" */
      './index'
    )
);

export default LazyIndexPage;
