import * as React from 'react';

const LazyAlgorithmSettingsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AlgorithmSettingsPage" */
      './index'
    )
);

export default LazyAlgorithmSettingsPage;
