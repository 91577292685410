import React, {createContext, useState, useCallback, ReactNode} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import {useTranslation} from 'hooks';

type ConfirmState = {
  isOpen: boolean;
  title?: string;
  message: string;
  confirmActionTitle: string;
  cancelActionTitle: string;
  onConfirm: () => void;
  onCancel: () => void;
};

type ConfirmOptions = {
  title?: string;
  message: string;
  confirmActionTitle?: string;
  cancelActionTitle?: string;
};

type ConfirmContextProps = {
  confirm: (options: ConfirmOptions) => Promise<boolean>;
};

export const ConfirmContext = createContext<ConfirmContextProps | undefined>(
  undefined
);

export const ConfirmProvider: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const {t} = useTranslation();

  const [confirmState, setConfirmState] = useState<ConfirmState>({
    isOpen: false,
    title: '',
    message: '',
    confirmActionTitle: '',
    cancelActionTitle: '',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const confirm = useCallback(
    ({
      title,
      message,
      confirmActionTitle = t('ConfirmationDialog.confirm'),
      cancelActionTitle = t('ConfirmationDialog.cancel'),
    }: ConfirmOptions): Promise<boolean> => {
      return new Promise((resolve) => {
        setConfirmState({
          isOpen: true,
          title,
          message,
          confirmActionTitle,
          cancelActionTitle,
          onConfirm: () => {
            resolve(true);
            setConfirmState((prev) => ({...prev, isOpen: false}));
          },
          onCancel: () => {
            resolve(false);
            setConfirmState((prev) => ({...prev, isOpen: false}));
          },
        });
      });
    },
    [t]
  );

  return (
    <ConfirmContext.Provider value={{confirm}}>
      {children}
      <Dialog open={confirmState.isOpen} onClose={confirmState.onCancel}>
        {confirmState.title && <DialogTitle>{confirmState.title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{confirmState.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmState.onCancel} color="primary">
            {confirmState.cancelActionTitle}
          </Button>
          <Button onClick={confirmState.onConfirm} color="primary" autoFocus>
            {confirmState.confirmActionTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmContext.Provider>
  );
};
