import * as React from 'react';

const LazyTasksModeratePage = React.lazy(() =>
  import(
    /* webpackChunkName: "TasksModeratePage" */
    './index'
  )
);

export default LazyTasksModeratePage;
