import * as React from 'react';
import {Typography} from '@material-ui/core';

import UserAvatar from 'components/UserAvatar';
import useStyles from './styles';
import {IUser} from 'common/types';
import cx from 'classnames';

type RenderNameProps = {
  renderOriginal: () => JSX.Element;
  name: string;
};
export type UserInfoCardProps = {
  user: {
    photoURL: IUser['photoURL'];
    userId: IUser['userId'];
    identifier: IUser['identifier'];
    fullName: IUser['fullName'];
  };
  className?: string;
  renderName?: (props: RenderNameProps) => JSX.Element;
};

const UserInfoCard: React.FunctionComponent<UserInfoCardProps> =
  React.forwardRef<HTMLDivElement, UserInfoCardProps>(
    ({user, className, ...rest}, ref) => {
      const classes = useStyles();

      const name = user.fullName || user.identifier;

      const renderNameOriginal = () => (
        <Typography className={classes.text} color="textPrimary" variant="h6">
          {name}
        </Typography>
      );
      const renderName = () => {
        if (rest.renderName) {
          return rest.renderName!({name, renderOriginal: renderNameOriginal});
        }

        return renderNameOriginal();
      };

      return (
        <div className={cx(classes.root, className)} ref={ref}>
          <UserAvatar
            photoURL={user.photoURL}
            name={name}
            className={classes.userAvatar}
          />
          <div className={classes.textContainer}>
            {renderName()}

            <Typography color="textSecondary" className={classes.text}>
              {user.identifier}
            </Typography>
          </div>
        </div>
      );
    }
  );

export default UserInfoCard;
