import * as React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import { animate, SVG_ID } from './helpers';

const Loader = ({ className }) => {
  const theme = useTheme();

  React.useLayoutEffect(() => {
    animate();
  }, []);

  return (
    <svg
      className={className}
      id={SVG_ID}
      version="1.1"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <polygon
        id={`${SVG_ID}_actor_1`}
        points="50.00,10.00 15.36,30.00 15.36,70.00 50.00,90.00 84.64,70.00 84.64,30.00"
        opacity="1"
        fill={theme.palette.primary.main}
        fillOpacity="1"
        strokeDasharray=""
      />
      <polygon
        id={`${SVG_ID}_actor_2`}
        points="50.00,25.00 28.35,37.50 28.35,62.50 50.00,75.00 71.65,62.50 71.65,37.50"
        opacity="1"
        fill={theme.palette.background.default}
        fillOpacity="1"
        strokeOpacity="1"
        strokeDasharray=""
      />
    </svg>
  );
};

export default Loader;
