import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  theme => ({
    dialogPaper: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    themeSelectItem: {
      padding: theme.spacing(1),
      height: theme.spacing(8),
      margin: theme.spacing(1),
    },
    themeSelectItemActive: {
      borderColor: theme.palette.primary.main,
    },
    checkbox: {
      padding: 0,
      paddingRight: theme.spacing(1),
    },
  }),
  {
    name: 'AppearanceDialog',
  }
);
