import * as React from 'react';

const LazyAccessDeniedPage = React.lazy(() =>
  import(
    /* webpackChunkName: "AccessDeniedPage" */
    './index'
  )
);

export default LazyAccessDeniedPage;
