import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '@material-ui/core';

export default makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr minmax(100px, 960px) 1fr',
      position: 'relative',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '120px 1fr 60px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(),
      },
    },
    logo: {
      verticalAlign: 'middle',
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(6),
        width: 100,
      },
    },
    avatarButton: {
      padding: 0,
    },
    rightSide: {
      height: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingRight: 12,
        marginLeft: 0,
      },
    },
    central: {
      display: 'flex',
    },
    leftSide: {
      height: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 12,
        justifyContent: 'flex-start',
      },
    },
    adminButton: {
      marginRight: theme.spacing(1),
      textTransform: 'none',
      fontSize: '1rem',
    },
  }),
  {
    name: 'Header',
  }
);
