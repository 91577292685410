import gql from 'graphql-tag';
import {
  ITask,
  IAlgorithm,
  ISample,
  IUser,
  IOption,
  IArbitrary,
  IAnswer,
} from 'common/types';

export interface IAlgorithmData {
  algorithm: {
    algorithmId: IAlgorithm['algorithmId'];
    description: IAlgorithm['description'];
    apiUrl: IAlgorithm['apiUrl'];
    notifications: IAlgorithm['notifications'];
    humanName: IAlgorithm['humanName'];
    section: IAlgorithm['section'];
    moderatedTasksCount: IAlgorithm['moderatedTasksCount'];
    unfinishedTasksCount: IAlgorithm['unfinishedTasksCount'];
    arbitrationCreationIntervalDays: IAlgorithm['arbitrationCreationIntervalDays'];
    nextArbitrationCreationDate: IAlgorithm['nextArbitrationCreationDate'];
  };
  arbitraries: {
    arbitraryId: IArbitrary['arbitraryId'];
  }[];
}

export interface IAlgorithmVariables {
  algorithmId: IAlgorithm['algorithmId'];
  userId: IUser['userId'];
}

export const AlgorithmQuery = gql`
  query Algorithm($algorithmId: Int!, $userId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      algorithmId
      description
      apiUrl
      humanName
      section
      moderatedTasksCount
      unfinishedTasksCount(userId: $userId)
      arbitrationCreationIntervalDays
      nextArbitrationCreationDate
    }
    arbitraries(algorithmId: $algorithmId) {
      arbitraryId
    }
  }
`;

export const AlgorithmFullInfoRootQuery = gql`
  query AlgorithmFullInfoRoot($algorithmId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      user {
        identifier
      }
      type
      description
      apiUrl
      storage
      options {
        id
        title
        color
      }
      allowMultipleOptionsSelection
      section
      humanName
      enabledFor {
        userId
        identifier
        fullName
        photoURL
      }
      active
      keywords
      notifications
      arbitrationCreationIntervalDays
      arbitrationCreationCode
      nextArbitrationCreationDate
    }
  }
`;

export interface IAlgorithmSectionHumanNameData {
  algorithm: {
    section: IAlgorithm['section'];
    humanName: IAlgorithm['humanName'];
  };
}
export interface IAlgorithmSectionHumanNameVariables {
  algorithmId: number;
}

export const AlgorithmSectionHumanNameQuery = gql`
  query AlgorithmSectionHumanName($algorithmId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      section
      humanName
    }
  }
`;

export const ArbitrariesListForTasksPageQuery = gql`
  query ArbitrariesListForTasksPage($algorithmId: Int!) {
    arbitraries(algorithmId: $algorithmId) {
      arbitraryId
      insertTime
    }
  }
`;

export interface IArbitrariesListForTasksPageData {
  arbitraries: {
    arbitraryId: IArbitrary['arbitraryId'];
    insertTime: IArbitrary['insertTime'];
  }[];
}

export interface IArbitrariesListForTasksPageVariables {
  algorithmId: IAlgorithm['algorithmId'];
}

export interface IArbitraryData {
  arbitrary: {
    arbitraryId: IArbitrary['arbitraryId'];
    algorithmId: IArbitrary['algorithmId'];
    humanName: IArbitrary['humanName'];
    comment: IArbitrary['comment'];
    tasksCount: IArbitrary['tasksCount'];
    finishedTasksCount: IArbitrary['finishedTasksCount'];
    answersStatistics: IArbitrary['answersStatistics'];
  };
}

export interface IArbitraryVariables {
  arbitraryId: IArbitrary['arbitraryId'];
  algorithmId: IArbitrary['algorithmId'];
}

export const ArbitraryQuery = gql`
  query Arbitrary($algorithmId: Int!, $arbitraryId: String!) {
    arbitrary(algorithmId: $algorithmId, arbitraryId: $arbitraryId) {
      arbitraryId
      algorithmId
      humanName
      comment
      tasksCount
      finishedTasksCount
      answersStatistics {
        optionId
        correct
        wrong
        unknown
      }
    }
  }
`;

export interface ICreateArbitraryData {
  createArbitrary: {
    algorithmId: IArbitrary['algorithmId'];
    arbitraryId: IArbitrary['arbitraryId'];
  };
}

export interface ICreateArbitraryVariables {
  arbitrary: {
    algorithmId: IArbitrary['algorithmId'];
    arbitraryId: IArbitrary['arbitraryId'];
  };
}

export const CreateArbitraryMutation = gql`
  mutation CreateArbitrary($arbitrary: ArbitraryCreateInput!) {
    createArbitrary(arbitrary: $arbitrary) {
      algorithmId
      arbitraryId
    }
  }
`;

export interface IUpdateArbitraryData {
  updateArbitrary: boolean;
}

export interface IUpdateArbitraryVariables {
  arbitrary: {
    algorithmId: IArbitrary['algorithmId'];
    arbitraryId: IArbitrary['arbitraryId'];
    humanName?: IArbitrary['humanName'];
    comment?: IArbitrary['comment'];
  };
}

export const UpdateArbitraryMutation = gql`
  mutation UpdateArbitrary($arbitrary: ArbitraryUpdateInput!) {
    updateArbitrary(arbitrary: $arbitrary)
  }
`;

export interface IArbitrariesListData {
  arbitraries: {
    algorithmId: IArbitrary['algorithmId'];
    arbitraryId: IArbitrary['arbitraryId'];
    humanName: IArbitrary['humanName'];
    tasksCount: IArbitrary['tasksCount'];
    finishedTasksCount: IArbitrary['finishedTasksCount'];
    insertTime: IArbitrary['insertTime'];
  }[];
}

export interface IArbitrariesListVariables {
  algorithmId: IAlgorithm['algorithmId'];
}

export const ArbitrariesListQuery = gql`
  query ArbitrariesList($algorithmId: Int!) {
    arbitraries(algorithmId: $algorithmId) {
      arbitraryId
      algorithmId
      humanName
      tasksCount
      finishedTasksCount
      insertTime
    }
  }
`;

export interface AlgorithmFullInfoVariables {
  algorithmId: number;
}

export interface IAlgorithmFullInfoForAdminAndMlEngineerData {
  algorithm: {
    user: {
      identifier: IUser['identifier'];
    };
    section: IAlgorithm['section'];
    humanName: IAlgorithm['humanName'];
    enabledFor: Array<{
      userId: IUser['userId'];
    }>;
    options: Array<{
      id: IOption['id'];
      title: IOption['title'];
      color: IOption['color'];
    }>;
  };
}

export interface IAlgorithmFullInfoVariables {
  algorithmId: IAlgorithm['algorithmId'];
}

export interface IAlgorithmFullInfoRootData {
  algorithm: {
    user: {
      identifier: IUser['identifier'];
    };
    section: IAlgorithm['section'];
    humanName: IAlgorithm['humanName'];
    enabledFor: {
      userId: IUser['userId'];
    }[];
    type: IAlgorithm['type'];
    description: IAlgorithm['description'];
    apiUrl: IAlgorithm['apiUrl'];
    storage: IAlgorithm['storage'];
    options: {
      id: IOption['id'];
      title: IOption['title'];
      color: IOption['color'];
    }[];
    allowMultipleOptionsSelection: IAlgorithm['allowMultipleOptionsSelection'];
    active: IAlgorithm['active'];
    notifications: IAlgorithm['notifications'];
    keywords: IAlgorithm['keywords'];
    arbitrationCreationCode: IAlgorithm['arbitrationCreationCode'];
    arbitrationCreationIntervalDays: IAlgorithm['arbitrationCreationIntervalDays'];
    nextArbitrationCreationDate: IAlgorithm['nextArbitrationCreationDate'];
  };
}

export const AlgorithmFullInfoForAdminAndMlEngineerQuery = gql`
  query AlgorithmFullInfoForAdminAndMlEngineer($algorithmId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      user {
        identifier
      }
      section
      humanName
      enabledFor {
        userId
        identifier
        fullName
        photoURL
      }
      options {
        id
        title
        color
      }
    }
  }
`;

export interface IAlgorithmsListData {
  algorithms: {
    algorithmId: IAlgorithm['algorithmId'];
    section: IAlgorithm['section'];
    humanName: IAlgorithm['humanName'];
    active: IAlgorithm['active'];
  }[];
}

export const AlgorithmsListQuery = gql`
  query AlgorithmsList {
    algorithms {
      algorithmId
      section
      humanName
      active
    }
  }
`;

export interface IAlgorithmUnfinishedTasksCountForUserData {
  algorithm: {
    unfinishedTasksCount: IAlgorithm['unfinishedTasksCount'];
  };
}

export interface IAlgorithmUnfinishedTasksCountForUserVariables {
  algorithmId: IAlgorithm['algorithmId'];
  arbitraryId: IArbitrary['arbitraryId'];
  userId: IUser['userId'];
}

export const AlgorithmUnfinishedTasksCountForUserQuery = gql`
  query UnfinishedTasksCountQuery(
    $algorithmId: Int!
    $userId: Int!
    $arbitraryId: String!
  ) {
    algorithm(algorithmId: $algorithmId) {
      unfinishedTasksCount(userId: $userId, arbitraryId: $arbitraryId)
    }
  }
`;

export interface IAnsweredAlgorithmsData {
  answeredAlgorithms: {
    algorithmId: IAlgorithm['algorithmId'];
    section: IAlgorithm['section'];
    humanName: IAlgorithm['humanName'];
  }[];
}
export interface IAnsweredAlgorithmsVariables {
  userId: IUser['userId'];
}

export const AnsweredAlgorithmsQuery = gql`
  query AnsweredAlgorithms($userId: Int!) {
    answeredAlgorithms(userId: $userId) {
      algorithmId
      section
      humanName
    }
  }
`;

export interface IAlgorithmsListDeferredStatisticsData {
  algorithms: {
    algorithmId: IAlgorithm['algorithmId'];
    moderatedTasksCount: IAlgorithm['moderatedTasksCount'];
    unfinishedTasksCount: IAlgorithm['unfinishedTasksCount'];
    answersDailyProductivity: IAlgorithm['answersDailyProductivity'];
  }[];
}

export interface IAlgorithmsListDeferredStatisticsVariables {
  algorithmsIds: IAlgorithm['algorithmId'][];
  lastDays?: number;
}

export const AlgorithmsListDeferredStatisticsQuery = gql`
  query AlgorithmsListDeferredStatistics(
    $lastDays: Int
    $algorithmsIds: [Int!]
  ) {
    algorithms(algorithmsIds: $algorithmsIds) {
      algorithmId
      moderatedTasksCount
      unfinishedTasksCount
      answersDailyProductivity(lastDays: $lastDays) {
        dayTime
        count
        currentUserCount
      }
    }
  }
`;

export const UsersListQuery = gql`
  query UsersList {
    users {
      userId
      identifier
      fullName
      photoURL
    }
  }
`;

export interface IUserVariables {
  userId: IUser['userId'];
}

export interface IUserData {
  user: {
    photoURL: IUser['photoURL'];
    identifier: IUser['identifier'];
    role: IUser['role'];
    fullName: IUser['fullName'];
    userId: IUser['userId'];
  };
}

export const UserQuery = gql`
  query User($userId: Int!) {
    user(userId: $userId) {
      userId
      photoURL
      identifier
      role
      fullName
    }
  }
`;

export interface IAlgorithmUsersListData {
  algorithmUsers: {
    userId: IUser['userId'];
    identifier: IUser['identifier'];
    fullName: IUser['fullName'];
    photoURL: IUser['photoURL'];
  }[];
}

export interface IAlgorithmUsersListVariables {
  algorithmId: IAlgorithm['algorithmId'];
}

export const AlgorithmUsersListQuery = gql`
  query AlgorithmUsersList($algorithmId: Int!) {
    algorithmUsers(algorithmId: $algorithmId) {
      userId
      identifier
      fullName
      photoURL
    }
  }
`;

export interface IUsersListWithRoleData {
  users: {
    userId: IUser['userId'];
    identifier: IUser['identifier'];
    role: IUser['role'];
    fullName: IUser['fullName'];
    photoURL: IUser['photoURL'];
  }[];
}

export const UsersListWithRoleQuery = gql`
  query UsersListWithRole {
    users {
      userId
      identifier
      role
      fullName
      photoURL
    }
  }
`;

export interface ITasksData {
  tasks: {
    taskId: ITask['taskId'];
    arbitraryId: ITask['arbitraryId'];
    representativeData: ITask['representativeData'];
    samples: ISample[];
    modelAnswer: ITask['modelAnswer'];
  }[];
}

export interface ITasksVariables {
  algorithmId: number;
  count?: number;
  skip?: number;
  arbitraryId?: string;
  taskId?: string;
  answer?: string;
  userId?: number;
  differentAnswers?: boolean;
  differentModelAnswer?: boolean;
  sameModelAnswer?: boolean;
  similarAnswersCount?: number;
  moderated?: Boolean;
}

export const TasksQuery = gql`
  query Tasks(
    $algorithmId: Int!
    $count: Int
    $skip: Int
    $arbitraryId: String
    $taskId: String
    $userId: Int
    $answer: String
    $differentAnswers: Boolean
    $differentModelAnswer: Boolean
    $sameModelAnswer: Boolean
    $moderated: Boolean
    $similarAnswersCount: Int
  ) {
    tasks(
      algorithmId: $algorithmId
      count: $count
      skip: $skip
      arbitraryId: $arbitraryId
      taskId: $taskId
      userId: $userId
      answer: $answer
      differentAnswers: $differentAnswers
      differentModelAnswer: $differentModelAnswer
      sameModelAnswer: $sameModelAnswer
      moderated: $moderated
      similarAnswersCount: $similarAnswersCount
    ) {
      algorithmId
      taskId
      arbitraryId
      representativeData
      samples {
        answer
        insertTime
        updateTime
        comment
        user {
          identifier
          userId
        }
      }
      modelAnswer
    }
  }
`;

export interface ITasksCountData {
  tasksCount: number;
}

export const TasksCountQuery = gql`
  query TasksCount(
    $algorithmId: Int!
    $arbitraryId: String
    $taskId: String
    $userId: Int
    $answer: String
    $differentAnswers: Boolean
    $differentModelAnswer: Boolean
    $sameModelAnswer: Boolean
    $moderated: Boolean
    $similarAnswersCount: Int
  ) {
    tasksCount(
      algorithmId: $algorithmId
      arbitraryId: $arbitraryId
      taskId: $taskId
      userId: $userId
      answer: $answer
      differentAnswers: $differentAnswers
      differentModelAnswer: $differentModelAnswer
      sameModelAnswer: $sameModelAnswer
      moderated: $moderated
      similarAnswersCount: $similarAnswersCount
    )
  }
`;

export interface IAlgorithmTypeData {
  algorithm: {
    type: IAlgorithm['type'];
  };
}

export interface IAlgorithmTypeVariables {
  algorithmId: number;
}

export const AlgorithmTypeQuery = gql`
  query AlgorithmType($algorithmId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      type
    }
  }
`;

export interface IAlgorithmInfoForTasksListSectionData {
  algorithm: {
    options: {
      id: IOption['id'];
      title: IOption['title'];
      color: IOption['color'];
    }[];
    type: IAlgorithm['type'];
    keywords: IAlgorithm['keywords'];
  };
}

export interface IAlgorithmInfoForTasksListSectionVariables {
  algorithmId: number;
}

export const AlgorithmInfoForTasksListSectionQuery = gql`
  query AlgorithmInfoForTasksListSection($algorithmId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      type
      options {
        id
        title
        color
      }
      keywords
    }
  }
`;

export interface IAlgorithmOptionsData {
  algorithm: {
    options: {
      id: IOption['id'];
      title: IOption['title'];
      color: IOption['color'];
    }[];
  };
}

export interface IAlgorithmOptionsVariables {
  algorithmId: IAlgorithm['algorithmId'];
}

export const AlgorithmOptionsQuery = gql`
  query AlgorithmOptions($algorithmId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      options {
        id
        title
        color
      }
    }
  }
`;

export interface IAlgorithmInfoForTasksModeratePageData {
  algorithm: {
    type: IAlgorithm['type'];
    options: {
      id: IOption['id'];
      title: IOption['title'];
      color: IOption['color'];
    }[];
    keywords: IAlgorithm['keywords'];
    allowMultipleOptionsSelection: IAlgorithm['allowMultipleOptionsSelection'];
  };
}
export interface IAlgorithmInfoForTasksModeratePageVariables {
  algorithmId: number;
}

export const AlgorithmInfoForTasksModeratePageQuery = gql`
  query AlgorithmInfoForTasksModeratePage($algorithmId: Int!) {
    algorithm(algorithmId: $algorithmId) {
      type
      options {
        id
        title
        color
      }
      keywords
      allowMultipleOptionsSelection
    }
  }
`;

export interface IAlgorithmTasksInfoForTasksModeratePageData {
  algorithm: {
    unfinishedTasksCount: IAlgorithm['unfinishedTasksCount'];
    moderatedTasksCount: IAlgorithm['moderatedTasksCount'];
  };
}

export interface IAlgorithmTasksInfoForTasksModeratePageVariables {
  algorithmId: IAlgorithm['algorithmId'];
  userId: IUser['userId'];
}

export const AlgorithmTasksInfoForTasksModeratePageQuery = gql`
  query AlgorithmTasksInfoForTasksModeratePage(
    $algorithmId: Int!
    $userId: Int!
  ) {
    algorithm(algorithmId: $algorithmId) {
      unfinishedTasksCount(userId: $userId)
      moderatedTasksCount
    }
  }
`;

export interface ITaskInfoForTasksModeratePageData {
  task: {
    taskId: ITask['taskId'];
    representativeData: ITask['representativeData'];
  };
}

export interface ITaskInfoForTasksModeratePageVariables {
  algorithmId: number;
  taskId: string | null;
}

export const TaskInfoForTasksModeratePageQuery = gql`
  query TaskInfoForTasksModeratePage($algorithmId: Int!, $taskId: String!) {
    task(algorithmId: $algorithmId, taskId: $taskId) {
      taskId
      representativeData
    }
  }
`;

export interface ISampleInfoForTasksModeratePageData {
  sample: {
    answer: ISample['answer'];
    comment: ISample['comment'];
    user: ISample['user'];
    insertTime: ISample['insertTime'];
    updateTime: ISample['updateTime'];
  } | null;
}

export interface ISampleInfoForTasksModeratePageVariables {
  algorithmId: number;
  taskId: string | null;
  userId: number;
}

export const SampleInfoForTasksModeratePageQuery = gql`
  query SampleInfoForTasksModeratePage(
    $algorithmId: Int!
    $taskId: String!
    $userId: Int!
  ) {
    sample(algorithmId: $algorithmId, taskId: $taskId, userId: $userId) {
      answer
      comment
      user {
        identifier
        userId
      }
      insertTime
      updateTime
    }
  }
`;

export interface IAlgorithmOptionsForStatisticsVariables {
  algorithmId: number;
  arbitraryId?: string;
  taskId?: string;
  answer?: string;
  userId?: number;
  differentAnswers?: boolean;
  differentModelAnswer?: boolean;
  sameModelAnswer?: boolean;
  similarAnswersCount?: number;
}

export interface IAlgorithmOptionsForStatisticsData {
  algorithmOptions: {
    title: IOption['title'];
    answersCount: IOption['answersCount'];
  }[];
}

export const AlgorithmOptionsForStatisticsQuery = gql`
  query AlgorithmOptionsForStatistics(
    $algorithmId: Int!
    $arbitraryId: String
    $taskId: String
    $userId: Int
    $answer: String
    $differentAnswers: Boolean
    $differentModelAnswer: Boolean
    $sameModelAnswer: Boolean
    $similarAnswersCount: Int
  ) {
    algorithmOptions(
      algorithmId: $algorithmId
      arbitraryId: $arbitraryId
      taskId: $taskId
      answer: $answer
      userId: $userId
      differentAnswers: $differentAnswers
      differentModelAnswer: $differentModelAnswer
      sameModelAnswer: $sameModelAnswer
      similarAnswersCount: $similarAnswersCount
    ) {
      title
      answersCount
    }
  }
`;

export const TasksProcessingsQuery = gql`
  query TasksProcessings($algorithmId: Int!) {
    tasksProcessings(algorithmId: $algorithmId) {
      tasksProcessingId
      user {
        identifier
      }
      insertTime
      allTasksCount
      finishedTasksCount
      failedTasksCount
      type
      status
      message
    }
  }
`;

export const TasksProcessingWithErrorsDetailsQuery = gql`
  query TasksProcessingWithErrorsDetails($tasksProcessingId: Int!) {
    tasksProcessing(tasksProcessingId: $tasksProcessingId) {
      user {
        identifier
      }
      insertTime
      allTasksCount
      finishedTasksCount
      failedTasksCount
      type
      tasks(status: "ERROR") {
        taskId
        message
      }
    }
  }
`;

export const DeleteTasksByProcessingIdMutation = gql`
  mutation DeleteTasksByProcessingId($tasksProcessingId: Int!) {
    deleteTasksByProcessingId(tasksProcessingId: $tasksProcessingId)
  }
`;

export const SignInMutation = gql`
  mutation SignIn(
    $idToken: String!
    $uid: String!
    $identifier: String!
    $fullName: String!
    $photoURL: String
  ) {
    signIn(
      idToken: $idToken
      uid: $uid
      identifier: $identifier
      fullName: $fullName
      photoURL: $photoURL
    ) {
      userId
      identifier
      role
      fullName
      photoURL
    }
  }
`;

export const SignOutMutation = gql`
  mutation SignOut {
    signOut
  }
`;

export const CreateAlgorithmMutation = gql`
  mutation CreateAlgorithm($algorithm: AlgorithmInput!) {
    createAlgorithm(algorithm: $algorithm) {
      algorithmId
    }
  }
`;

export const UpdateAlgorithmMutation = gql`
  mutation UpdateAlgorithm(
    $algorithmId: Int!
    $algorithm: AlgorithmInput!
    $updateAllTasks: Boolean!
  ) {
    updateAlgorithm(
      algorithmId: $algorithmId
      algorithm: $algorithm
      updateAllTasks: $updateAllTasks
    )
  }
`;

export const UpdateUserMutation = gql`
  mutation UpdateUser($userId: Int!, $user: UserInput!) {
    updateUser(userId: $userId, user: $user)
  }
`;

export interface ICreateUserData {
  createUser: boolean;
}

export interface ICreateUserVariables {
  user: {
    role: IUser['role'];
    identifier: IUser['identifier'];
    fullName: IUser['fullName'];
  };
}

export const CreateUserMutation = gql`
  mutation CreateUser($user: UserInput!) {
    createUser(user: $user) {
      userId
    }
  }
`;

export interface IAddTasksData {
  addTasks: boolean;
}

export interface IAddTasksVariables {
  algorithmId: number;
  moderateLimit: number;
  arbitraryId: string | null;
  tasksFile: any;
  priority: number;
}

export const AddTasksMutation = gql`
  mutation AddTasks(
    $algorithmId: Int!
    $moderateLimit: Int!
    $arbitraryId: String
    $tasksFile: Upload!
    $priority: Int
  ) {
    addTasks(
      algorithmId: $algorithmId
      moderateLimit: $moderateLimit
      arbitraryId: $arbitraryId
      tasksFile: $tasksFile
      priority: $priority
    )
  }
`;

export interface ILockTasksData {
  lockTasks: {
    taskId: ITask['taskId'];
    representativeData: ITask['representativeData'];
  }[];
}

export interface ILockTasksVariables {
  algorithmId: number;
  limit: number;
}

export const LockTasksMutation = gql`
  mutation LockTasks($algorithmId: Int!, $limit: Int!) {
    lockTasks(algorithmId: $algorithmId, limit: $limit) {
      taskId
      representativeData
    }
  }
`;

export interface IModerateTaskData {
  moderateTask: boolean;
}

export interface IModerateTaskVariables {
  algorithmId: number;
  taskId: string;
  answer: IAnswer;
  comment?: string | null;
}

export const ModerateTaskMutation = gql`
  mutation ModerateTask(
    $algorithmId: Int!
    $taskId: String!
    $answer: Any!
    $comment: String
  ) {
    moderateTask(
      algorithmId: $algorithmId
      taskId: $taskId
      answer: $answer
      comment: $comment
    )
  }
`;

export const DeleteAllAlgorithmDataMutation = gql`
  mutation DeleteAllAlgorithmData($algorithmId: Int!) {
    deleteAlgorithm(algorithmId: $algorithmId)
    deleteTasksAndSamples(algorithmId: $algorithmId)
  }
`;

export interface IDeactivateSampleData {
  deactivateSample: boolean;
}

export interface IDeactivateSampleVariables {
  algorithmId: number;
  taskId: string | null;
  userId: number;
}

export const DeactivateSampleMutation = gql`
  mutation DeactivateSample(
    $algorithmId: Int!
    $taskId: String!
    $userId: Int!
  ) {
    deactivateSample(
      algorithmId: $algorithmId
      taskId: $taskId
      userId: $userId
    )
  }
`;

export interface IDeleteTaskData {
  deleteTask: boolean;
}

export interface IDeleteTaskVariables {
  algorithmId: number;
  taskId: string;
}

export const DeleteTaskMutation = gql`
  mutation DeleteTask($algorithmId: Int!, $taskId: String!) {
    deleteTask(algorithmId: $algorithmId, taskId: $taskId)
  }
`;

export interface IDeleteTasksAndSamplesData {
  deleteTasksAndSamples: boolean;
}

export const DeleteTasksAndSamplesMutation = gql`
  mutation DeleteTasksAndSamples(
    $algorithmId: Int!
    $taskIds: [String]
    $forceDeleteAll: Boolean
    $notModeratedOnly: Boolean
  ) {
    deleteTasksAndSamples(
      algorithmId: $algorithmId
      taskIds: $taskIds
      forceDeleteAll: $forceDeleteAll
      notModeratedOnly: $notModeratedOnly
    )
  }
`;

export interface IMessagesVariables {
  userId: number;
}

export interface IMessagesData {
  user: {
    identifier: IUser['identifier'];
    messages: IUser['messages'];
  };
}

export const MessagesQuery = gql`
  query Messages($userId: Int!) {
    user(userId: $userId) {
      identifier
      messages {
        text
        messageId
        checked
        time
      }
    }
  }
`;

export interface IUserDailyProductivityVariables {
  userId: IUser['userId'];
  algorithmId: IAlgorithm['algorithmId'];
  from: Date;
  to: Date;
}

export interface IUserDailyProductivityData {
  user: {
    dailyProductivity: IUser['dailyProductivity'];
  };
}

export const UserDailyProductivityQuery = gql`
  query UserDailyProductivity(
    $userId: Int!
    $algorithmId: Int!
    $from: DateTime
    $to: DateTime
  ) {
    user(userId: $userId) {
      dailyProductivity(algorithmId: $algorithmId, from: $from, to: $to) {
        dayTime
        count
      }
    }
  }
`;

export interface IUserHourlyProductivityVariables {
  userId: IUser['userId'];
  algorithmId: IAlgorithm['algorithmId'];
  from: Date;
  to: Date;
}

export interface IUserHourlyProductivityData {
  user: {
    hourlyProductivity: IUser['hourlyProductivity'];
  };
}

export const UserHourlyProductivityQuery = gql`
  query UserHourlyProductivity(
    $userId: Int!
    $algorithmId: Int!
    $from: DateTime
    $to: DateTime
  ) {
    user(userId: $userId) {
      hourlyProductivity(algorithmId: $algorithmId, from: $from, to: $to) {
        hourTime
        count
      }
    }
  }
`;

export interface IStarredAlgorithmsListData {
  user: {
    starredAlgorithms: {algorithmId: IAlgorithm['algorithmId']}[];
  };
}

export interface IStarredAlgorithmsListVariables {
  userId: IUser['userId'];
}

export const StarredAlgorithmsListQuery = gql`
  query StarredAlgorithmsList($userId: Int!) {
    user(userId: $userId) {
      starredAlgorithms {
        algorithmId
      }
    }
  }
`;

export interface ToggleAlgorithmStarredData {
  toggleAlgorithmStarred: boolean;
}

export interface ToggleAlgorithmStarredVariables {
  algorithmId: IAlgorithm['algorithmId'];
}

export const ToggleAlgorithmStarredMutation = gql`
  mutation ToggleAlgorithmStarred($algorithmId: Int!) {
    toggleAlgorithmStarred(algorithmId: $algorithmId)
  }
`;

export interface GetSignedUrlForUploadingFileToS3Variables {
  basePath?: string;
  fileExtension: string;
}

export const GetSignedUrlForUploadingFileToS3Query = gql`
  query GetSignedUrlForUploadingFileToS3(
    $basePath: String
    $fileExtension: String!
  ) {
    getSignedUrlForUploadingFileToS3(
      basePath: $basePath
      fileExtension: $fileExtension
    )
  }
`;

export type AlgorithmModeratorsPerformanceStatsVariables = {
  algorithmId: number;
  start: string;
  end: string;
  timezoneOffsetInMinutes: number;
};

export type AlgorithmModeratorsPerformanceStatsData = {
  user: {
    userId: number;
    fullName: string;
    photoURL: string;
  };
  answersCountByDate: Record<string, number>;
  minutesSpentByDate: Record<string, number>;
};

export const AlgorithmModeratorsPerformanceStatsQuery = gql`
  query AlgorithmModeratorsPerformanceStats(
    $algorithmId: Int!
    $start: String!
    $end: String!
    $timezoneOffsetInMinutes: Int!
  ) {
    algorithmModeratorsPerformanceStats(
      algorithmId: $algorithmId
      start: $start
      end: $end
      timezoneOffsetInMinutes: $timezoneOffsetInMinutes
    ) {
      user {
        userId
        fullName
        photoURL
      }
      answersCountByDate
      minutesSpentByDate
    }
  }
`;
