import * as React from 'react';

const LazyTasksAddPage = React.lazy(() =>
  import(
    /* webpackChunkName: "TasksAddPage" */
    './index'
  )
);

export default LazyTasksAddPage;
