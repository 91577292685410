import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
    },
    userAvatar: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    textContainer: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  {name: 'UserInfoCard'}
);
