import chroma from 'chroma-js';
import qs from 'query-string';

export const DEFAULT_OPTION_COLOR = '#FF0000';
const PUNCTUATION = './,][{}!@#$%^&*()_-+="\':|/\\~` \n?';

export function throttle(func, ms) {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments;
      // @ts-ignore
      savedThis = this;
      return;
    }

    // @ts-ignore
    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}

export function createQueryString(params) {
  const filteredParams = Object.entries(params).reduce(
    (acc, [key, value]) =>
      value
        ? {
            ...acc,
            [key]: value,
          }
        : acc,
    {}
  );

  return qs.stringify(filteredParams);
}

export function parseQueryString(queryString) {
  return qs.parse(queryString);
}

export function filterQueryParams(queryParams) {
  return Object.keys(queryParams)
    .filter(
      (key) => queryParams[key] !== null && queryParams[key] !== undefined
    )
    .reduce((acc, key) => {
      acc[key] = queryParams[key];
      return acc;
    }, {});
}

export function createColorsRange(length) {
  return chroma
    .scale(['red', 'yellow', 'green', 'blue', 'magenta'])
    .colors(length);
}

export const removeTypename = (value) => {
  if (Array.isArray(value)) {
    value.forEach((item) => {
      delete item.__typename;
    });
    return value;
  }

  if (typeof value === 'object') {
    delete value.__typename;
    return value;
  }
};

export function ensureNotNull<T>(value: T | null, valueName: string): T {
  if (value === null) {
    throw new Error(`ensureNotNull: '${valueName}' is null`);
  }

  return value;
}

export const range = (start: number, end?: number, step?: number): number[] => {
  const startOfRange = typeof end === 'undefined' ? 0 : start;
  const endOfRange = typeof end === 'undefined' ? start : end;
  const stepOfRange = typeof step === 'undefined' ? 1 : step;

  const result: number[] = [];
  for (let i = startOfRange; i < endOfRange; i += stepOfRange) {
    result.push(i);
  }

  return result;
};

export function getWordsArray(string) {
  return [...string].reduce((acc, char, index) => {
    const lastElement = acc[acc.length - 1];
    if (!lastElement) {
      return [{value: char, index}];
    }
    if (PUNCTUATION.includes(char)) {
      return [...acc, {value: char, index}];
    }

    const lastElementValue = lastElement.value;
    if (PUNCTUATION.includes(lastElementValue[lastElementValue.length - 1])) {
      return [...acc, {value: char, index}];
    }
    if (!isNaN(lastElementValue[0]) && isNaN(char)) {
      return [...acc, {value: char, index}];
    }
    return [
      ...acc.slice(0, acc.length - 1),
      {value: lastElementValue + char, index: lastElement.index},
    ];
  }, []);
}

export function extendWithKeywords(keywords, value, className) {
  const wordsArray = getWordsArray(value);
  const keywordsArray = keywords
    .map((keyword) => keyword.toLowerCase())
    .sort((a, b) => b.length - a.length);
  return wordsArray.reduce((acc, {value}) => {
    let resultValue = value;
    for (const keywordIndex in keywordsArray) {
      const startIndex = value
        .toLowerCase()
        .indexOf(keywordsArray[keywordIndex]);
      if (startIndex === 0) {
        resultValue = `<span class="${className}">${value}</span>`;
        break;
      }
    }
    return acc + resultValue;
  }, '');
}
