import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {SnackbarOrigin} from '@material-ui/core';
import {Route, Router} from 'react-router-dom';
import {ApolloProvider} from '@apollo/react-hooks';
import {SnackbarProvider} from 'notistack';
import {IntlProvider} from 'providers/IntlProvider';
import {UserProvider} from 'providers/UserProvider';
import {ThemeProvider} from 'providers/ThemeProvider';
import {RootPage} from 'pages/RootPage';
import {createBrowserHistory} from 'history';
import {apolloClient} from 'apollo';
import {ConfirmProvider} from 'providers/ConfirmProvider';

const SNACKBAR_MAX_SHACK = 1;
const SNACKBAR_AUTO_HIDE_DURATION_MS = 2000;
const SNACKBAR_PROVIDER_ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const history = createBrowserHistory();

const App: React.FunctionComponent = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <UserProvider>
        <ThemeProvider>
          <IntlProvider>
            <ConfirmProvider>
              <Router history={history}>
                <CssBaseline />
                <SnackbarProvider
                  dense
                  maxSnack={SNACKBAR_MAX_SHACK}
                  autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_MS}
                  anchorOrigin={SNACKBAR_PROVIDER_ANCHOR_ORIGIN}
                >
                  <Route component={RootPage} />
                </SnackbarProvider>
              </Router>
            </ConfirmProvider>
          </IntlProvider>
        </ThemeProvider>
      </UserProvider>
    </ApolloProvider>
  );
};

export {App};
