import * as React from 'react';

const LazyAdminPage = React.lazy(() =>
  import(
    /* webpackChunkName: "AdminPage" */
    './index'
  )
);

export default LazyAdminPage;
