import {useIntl} from 'react-intl';
import {useCallback} from 'react';

type PrimitiveType = string | number | boolean | null | undefined | Date;

export const useTranslation = (prefix?: string) => {
  const {formatMessage} = useIntl();

  const translate = useCallback(
    (id: string, values?: Record<string, PrimitiveType>) =>
      formatMessage({id: prefix ? `${prefix}.${id}` : id}, values),
    [formatMessage, prefix]
  );

  return {
    t: translate,
  };
};
