import * as React from 'react';

import {VERIFIED_USER_ROLES} from 'common/types';
import {useCurrentUser} from 'hooks';

export interface PrivateContainerProps {
  component?: React.ComponentType;
  roles?: string[];
  children?: any;
}

const PrivateContainer: React.FunctionComponent<PrivateContainerProps> = ({
  component: Component = React.Fragment,
  roles = VERIFIED_USER_ROLES,
  children,
}: PrivateContainerProps) => {
  const {user, isSignedIn} = useCurrentUser();

  if (!isSignedIn) {
    return null;
  }

  const isAccessDenied = user.role && !roles.includes(user.role);
  if (isAccessDenied) {
    return null;
  }

  return <Component>{children}</Component>;
};

export default PrivateContainer;
