import makeStyles from '@material-ui/core/styles/makeStyles';
import {Theme} from '@material-ui/core';

export default makeStyles(
  (theme: Theme) => ({
    userInfoCard: {
      padding: theme.spacing(2),
    },
    localeMenuItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    localeMenuItemLabel: {
      height: theme.spacing(5),
      display: 'flex',
      alignItems: 'center',
    },
    menuItemIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  }),
  {
    name: 'UserMenu',
  }
);
