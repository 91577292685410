import * as React from 'react';
import {Redirect, Route, RouteProps, useLocation} from 'react-router-dom';
import {PageRoute, UserRole} from 'common/types';
import {VERIFIED_USER_ROLES} from 'common/types';
import {createQueryString} from 'utils';
import {useCurrentUser} from 'hooks';

interface PrivateRouteProps extends RouteProps {
  roles?: UserRole[];
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  render: renderComponent,
  roles = VERIFIED_USER_ROLES,
  ...rest
}) => {
  const {isSignedIn, user} = useCurrentUser();

  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isSignedIn) {
          return (
            <Redirect
              to={{
                pathname: PageRoute.SignIn,
                search: createQueryString({
                  from: location.pathname + location.search,
                }),
              }}
            />
          );
        }

        if (!roles.includes(user.role)) {
          return <Redirect to={PageRoute.AccessDenied} />;
        }

        return Component ? (
          <Component {...props} />
        ) : renderComponent ? (
          renderComponent(props)
        ) : null;
      }}
    />
  );
};

export default PrivateRoute;
