import * as React from 'react';

const LazyAlgorithmsListPage = React.lazy(() =>
  import(
    /* webpackChunkName: "AlgorithmsListPage" */
    './index'
  )
);

export default LazyAlgorithmsListPage;
