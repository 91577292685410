import * as React from 'react';
import {Route, Switch} from 'react-router-dom';

import {UserRole} from 'common/types';
import PrivateContainer from 'components/PrivateContainer';
import PrivateRoute from 'components/PrivateRoute';
import Header from './Header';
import useStyles from './styles';
import LoadingPage from 'pages/LoadingPage';
import LazyUsersListPage from 'pages/UsersListPage/lazy';
import LazyUserPage from 'pages/UserPage/lazy';
import LazyTasksAddPage from 'pages/TasksAddPage/lazy';
import LazyTasksModeratePage from 'pages/TasksModeratePage/lazy';
import LazySignInPage from 'pages/SignInPage/lazy';
import LazyMapPage from 'pages/MapPage/lazy';
import LazyIndexPage from 'pages/IndexPage/lazy';
import LazyAlgorithmsListPage from 'pages/AlgorithmsListPage/lazy';
import LazyAlgorithmPage from 'pages/AlgorithmPage/lazy';
import LazyAlgorithmSettingsPage from 'pages/AlgorithmSettingsPage/lazy';
import LazyAlgorithmCreatePage from 'pages/AlgorithmCreatePage/lazy';
import LazyAdminPage from 'pages/AdminPage/lazy';
import LazyAccessDeniedPage from 'pages/AccessDeniedPage/lazy';
import {PageRoute} from 'common/types';
import {useCurrentUser} from 'hooks';

const RenderIfAppReady = ({children}) => {
  const {isAppReady} = useCurrentUser();
  if (!isAppReady) {
    throw new Promise(() => {});
  }

  return children;
};

const RootPage: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <>
      <PrivateContainer
        roles={[
          UserRole.NewUser,
          UserRole.Moderator,
          UserRole.MlEngineer,
          UserRole.Admin,
          UserRole.Root,
          UserRole.None,
        ]}
      >
        <Header />
      </PrivateContainer>
      <div className={classes.root}>
        <React.Suspense fallback={<LoadingPage />}>
          <RenderIfAppReady>
            <Switch>
              <PrivateRoute
                roles={[
                  UserRole.NewUser,
                  UserRole.Moderator,
                  UserRole.MlEngineer,
                  UserRole.Admin,
                  UserRole.Root,
                  UserRole.None,
                ]}
                path={PageRoute.AccessDenied}
                exact
                component={LazyAccessDeniedPage}
              />
              <Route path={PageRoute.SignIn} exact component={LazySignInPage} />
              <PrivateRoute
                exact
                path={PageRoute.Index}
                component={LazyIndexPage}
              />
              <PrivateRoute
                path={PageRoute.TasksAdd}
                roles={[UserRole.Root, UserRole.Admin, UserRole.MlEngineer]}
                component={LazyTasksAddPage}
              />
              <PrivateRoute
                path={PageRoute.TasksSimpleAdd}
                roles={[UserRole.Root, UserRole.Admin, UserRole.MlEngineer]}
                component={LazyTasksAddPage}
              />
              <PrivateRoute
                path={PageRoute.TasksModerate}
                component={LazyTasksModeratePage}
              />

              <PrivateRoute
                path={PageRoute.AlgorithmSettings}
                roles={[UserRole.Admin, UserRole.Root, UserRole.MlEngineer]}
                component={LazyAlgorithmSettingsPage}
              />
              <PrivateRoute
                path={PageRoute.AlgorithmAdd}
                roles={[UserRole.Root]}
                component={LazyAlgorithmCreatePage}
              />
              <PrivateRoute
                path={PageRoute.Algorithm}
                component={LazyAlgorithmPage}
              />
              <PrivateRoute
                path={PageRoute.AlgorithmsList}
                component={LazyAlgorithmsListPage}
              />
              <PrivateRoute path={PageRoute.User} component={LazyUserPage} />
              <PrivateRoute
                path={PageRoute.Map}
                roles={[UserRole.Root]}
                component={LazyMapPage}
              />
              <PrivateRoute
                path={PageRoute.UsersList}
                roles={[UserRole.Admin, UserRole.Root]}
                component={LazyUsersListPage}
              />
              <PrivateRoute
                path={PageRoute.Admin}
                roles={[UserRole.Admin, UserRole.Root]}
                component={LazyAdminPage}
              />
            </Switch>
          </RenderIfAppReady>
        </React.Suspense>
      </div>
    </>
  );
};

export {RootPage};
